import React, { useEffect } from "react";
import { Stack, IconButton, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { StyledTextfield } from "../../../common/StyledComponents";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

export default function AgentsToolbar(props) {
  const { loadAgentRows, defaultSearchValue } = props;
  const [searchValue, setSearchValue] = React.useState(
    defaultSearchValue || ""
  );

  // Search effect that triggers after a delay from typing.
  const searchThrottleTimeout = React.useRef();
  useEffect(() => {
    clearTimeout(searchThrottleTimeout.current);
    if (searchValue.trim().length > 1) {
      searchThrottleTimeout.current = setTimeout(() => {
        loadAgentRows(searchValue);
      }, 300);
    }
  }, [searchValue, loadAgentRows]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <StyledTextfield
        autoFocus
        placeholder="Search results"
        sx={{ width: "360px", py: 0.75 }}
        endAdornment={
          searchValue?.trim().length > 0 ? (
            <IconButton
              size="small"
              sx={{ mr: -0.5 }}
              onClick={() => {
                setSearchValue("");
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton size="small" sx={{ mr: -0.5 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )
        }
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </Stack>
  );
}
