import html2canvas from "html2canvas";
import pick from "lodash/pick";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LightbulbIcon from "@mui/icons-material/EmojiObjects";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import MemoryIcon from "@mui/icons-material/Memory";
import { isNil, isString, isNaN, difference } from "lodash";
import { isFirefox } from "react-device-detect";
import { ALLOWED_ACTIONS } from "../context/UserContext";

/** Converts long numbers to shorthand abbreviated form. */
export const numberAbbrev = (currency, number) => {
  //TODO kinda hate that currency is the first param here instead of number
  const numDecimals = number > 999_999_999 ? 2 : 0;
  const formatter = new Intl.NumberFormat("en-US", {
    ...(currency ? { style: "currency", currency: currency } : {}),
    notation: "compact",
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  });

  return formatter.format(number);
};

/** Adds number suffix for ordering */

export const addSuffix = (number) => {
  let suffix = ["th", "st", "nd", "rd"];
  let modHundred = number % 100;
  return (
    number + (suffix[(modHundred - 20) % 10] || suffix[modHundred] || suffix[0])
  );
};

export const CURRENCIES = [
  { name: "Euro (€)", value: "EUR" },
  { name: "US Dollar ($)", value: "USD" },
];

const randomColors = [
  "#1f76b8",
  "#7648c7",
  "#b03f83",
  "#d6181a",
  "#16941f",
  "#de8500",
  "#168794",
];
/** Returns a random color string from the randomColors array. */
export const getColorFromString = (string) => {
  return randomColors[(string?.charCodeAt(0) + string?.length) % 7];
};

export const formatPhoneNumber = (countryCode, number) => {
  //Filter only numbers from the input
  let cleaned = ("" + number).replace(/\D/g, "");
  //Check if the input is of correct
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = "+" + countryCode;
    return [intlCode, " (", match[1], ") ", match[2], "-", match[3]].join("");
  }

  return null;
};

/* Usage: exportAsImage(imageRef.current, filename);
 * Returns an array with images from the provided card. flagsOnly param will only return an array of images if
 * the card has at least one split flag. */
export const exportAsImage = async (inputElement, isImageCard) => {
  let el = inputElement;
  let canvasArray = [];
  // Removes all box shadows from the element and its children.
  el.style.boxShadow = "none !important";
  removeBoxShadowsRecursively(el);
  let splitFlags = el.getElementsByClassName("splitFlag");
  if (splitFlags.length < 1) {
    if (isImageCard) {
      canvasArray.push(
        html2canvas(el.childNodes[0].childNodes[1], {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          onclone: function (document) {
            showFullTables(document);
          },
        }).then((res) => res.toDataURL("image/png", 1.0))
      );
    }
  } else {
    Array.from(splitFlags).forEach((section) => {
      canvasArray.push(
        html2canvas(section, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          onclone: function (document) {
            showFullTables(document);
          },
        }).then((res) => res.toDataURL("image/png", 1.0))
      );
    });
  }

  return Promise.all(canvasArray);

  // return dataURItoBlob(image);
};

function removeBoxShadowsRecursively(el) {
  for (var i = 0; i < el.childNodes.length; i++) {
    if (el.childNodes[i].nodeName.toLowerCase() === "div") {
      el.style.boxShadow = "none !important";
      removeBoxShadowsRecursively(el.childNodes[i]);
    }
  }
}

function showFullTables(doc) {
  let splitSections = doc.getElementsByClassName("splitFlag");
  for (let i = 0; i < splitSections.length; i++) {
    const tables = splitSections
      .item(i)
      .getElementsByClassName("MuiTableContainer-root");
    if (tables.length > 0) {
      splitSections.item(i).style.width = "fit-content";
    }
  }
}

function decacheImages(doc) {
  let imgList = doc.getElementsByTagName("img");
  for (let i = 0; i < imgList.length; i++) {
    let originalSrc = imgList.item(i).src;
    if (originalSrc.includes("rule5-dev-cards")) {
      const newSrc = originalSrc + "?" + Math.random();
      imgList.item(i).src = newSrc;
    }
  }
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function aggregateConnections(connections) {
  if (!connections) {
    return null;
  }
  return connections.reduce(function (connection, obj) {
    let key = obj["linkedinProfileId"];
    if (!connection[key]) {
      // First time aggregating on this person.
      // Get only the fields specific to the person being aggregated on
      // (all aggregated connection info will go in the connectionPersonInfo array)
      let personFields = [];
      Object.keys(obj).forEach(function (field) {
        if (!/connection/gi.test(field)) {
          personFields.push(field);
        }
      });
      connection[key] = pick(obj, personFields);
      connection[key].connectionPersonInfo = [];
    }

    connection[key].connectionPersonInfo.push({
      ...obj.connectionPersonInfo,
      connectionEmail: obj.connectionEmail,
      connectionType: obj.connectionType,
      connectionOrgName: obj.connectionOrgName,
    });
    return connection;
  }, {});
}

/* Assumes profile id's are alphanumeric with hyphens. */
export function getLinkedInProfile(url) {
  return url?.match(/(linkedin\.com\/in\/)([a-zA-Z0-9-À-ž-%]*)/)?.[2];
}

const LINKEDIN_URL_REGEX = /https:\/\/www\.linkedin\.com\/in\/(.+)/;
const LINKEDIN_COMPANY_URL_REGEX =
  /https:\/\/www\.linkedin\.com\/company\/(.+)/;

export const isValidProfileUrl = (profileUrl) => {
  if (!profileUrl || profileUrl.trim() === "") {
    return false;
  }

  const trimmedUrl = profileUrl.trim().replace(/\/+$/, "");

  return LINKEDIN_URL_REGEX.test(trimmedUrl);
};

export const isValidCompanyUrl = (companyUrl) => {
  if (!companyUrl || companyUrl.trim() === "") {
    return false;
  }

  const trimmedUrl = companyUrl.trim().replace(/\/+$/, "");

  return LINKEDIN_COMPANY_URL_REGEX.test(trimmedUrl);
};

/* Gets a month name from integer (1-indexed) */
export function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "long",
  });
}

/* Given a lowercase user profile object, convert to uppercase user profile object and assigns defaults
and converts language objects to plain string */
export function regulateProfile(profile) {
  if (profile) {
    return {
      Boards: profile.boards ? profile.boards : [],
      Education: profile.education ? profile.education : [],
      WorkExperience: profile.workExperience ? profile.workExperience : [],
      // maps languages & proficiency to a simple language string.
      Languages: profile.languages
        ? profile.languages.map((language) => language.name)
        : [],
      Summary: profile.summary ? profile.summary : "",
      ...profile,
    };
  } else return null;
}

/* Given a user profile object (used to show additional info about people - experience, education, etc), determine if 
there is information to display */
export function checkPersonProfileExists(profile) {
  if (
    profile?.Boards?.length > 0 ||
    profile?.Education?.length > 0 ||
    profile?.WorkExperience?.length > 0 ||
    profile?.Languages?.length > 0 ||
    profile?.Summary?.trim().length > 0
  ) {
    return true;
  } else return false;
}

/* True if there is any block text */
export const hasRteContent = (rteContent) => {
  return (
    rteContent &&
    (rteContent.blocks?.length > 1 ||
      rteContent.blocks.some((block) => block.text))
  );
};

/* Given a person's role, return corresponding color. */
export function roleToColor(role) {
  switch (role) {
    // green
    case "Economic Buyer":
      return "#6bc251";
    // gold
    case "Champion":
      return "#ffd726";
    // purple
    case "Decision Maker":
      return "#7e33ff";
    // red
    case "Technical Buyer":
      return "#d43d3d";
    // red
    case "Technical Decision Maker":
      return "#d43d3d";
    default:
      return null;
  }
}

/* Given a person's role, return corresponding icon. */
export function roleToIcon(role) {
  switch (role) {
    case "Economic Buyer":
      return (
        <AttachMoneyIcon
          sx={{ height: "16px", width: "16px", color: "white" }}
        />
      );
    case "Decision Maker":
      return (
        <LightbulbIcon sx={{ height: "16px", width: "16px", color: "white" }} />
      );
    case "Champion":
      return (
        <TrophyIcon sx={{ height: "16px", width: "16px", color: "white" }} />
      );
    case "Technical Buyer":
      return (
        <MemoryIcon sx={{ height: "16px", width: "16px", color: "white" }} />
      );
    case "Technical Decision Maker":
      return (
        <MemoryIcon sx={{ height: "16px", width: "16px", color: "white" }} />
      );
    default:
      return null;
  }
}

/* Adds a suffix to the end of a day */
export function daySuffix(day) {
  let suffix = "";

  switch (day) {
    case "1":
    case "21":
    case "31":
      suffix = "st";
      break;
    case "2":
    case "22":
      suffix = "nd";
      break;
    case "3":
    case "23":
      suffix = "rd";
      break;
    default:
      suffix = "th";
  }

  return day + suffix;
}

/* Given a string (card type), replace spaces with hyphens and convert to lowercase */
export function cardTypeToId(cardType) {
  return cardType?.replaceAll(" ", "-").toLowerCase();
}

/* Given a timestamp, calculate and return a string describing the relative time. */
export function getRelativeTimestamp(timestamp) {
  let minutes = (new Date() - new Date(timestamp)) / 60000;
  if (minutes > 1320) {
    // Show time in days
    let num = Math.round(minutes / 1440);
    return num + " day" + (num > 1 ? "s" : "") + " ago";
  } else if (minutes > 50) {
    // Show time in hours
    let num = Math.round(minutes / 60);
    return num + " hour" + (num > 1 ? "s" : "") + " ago";
  } else if (minutes > 2) {
    // Show time in minutes
    let num = Math.round(minutes);
    return Math.round(minutes) + " minute" + (num > 1 ? "s" : "") + " ago";
  } else {
    return "Just now";
  }
}

/* Detect whether the current window is being displayed in an iframe. */
export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const PLEASE_UPGRADE_TEXT = "To upgrade your subscription, please contact sales@rule5.io.";

export const percentFormatter = (number) => {
  return parseFloat(number * 100).toFixed(2) + "%";
};

export function removeLeadingNewlinesEtc(str) {
  if (isString(str)) {
    return str?.replace(/^[^a-zA-Z0-9<]+/, "");
  } else {
    console.log("Unexpected missing string");
  }
}

/** Formats js Date object into string with day suffix like e.g. "March 2nd, 2023" */
export function formatDate(date) {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${month} ${addSuffix(day)}, ${year}`;
}

/** Combines user's first name and last name to create full name. */
export function formatName(user) {
  const firstName = user.firstName;
  const lastName = user.lastName;
  let fullName = "--";
  if (firstName) {
    fullName = firstName;
    if (lastName) {
      fullName += " " + lastName;
    }
  } else if (lastName) {
    fullName = lastName;
  }
  return fullName;
}
/** Converts a mix of plain text and HTML to HTML including line breaks in order to copy formatted data to clipboard. */
export function copyMixedHtml(input) {
  // Firefox is said to not support clipboard.write().
  if (isFirefox) {
    navigator.clipboard.writeText(input);
  }

  const convertedInput = input.replace(
    /(?:\r\n|\r|\n)(?!<\/?[a-z]+\s*\/?>)/g,
    "<br>"
  );
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = input;
  navigator.clipboard.write([
    new window.ClipboardItem({
      "text/plain": new Blob([tempDiv.innerText], { type: "text/plain" }),
      "text/html": new Blob([convertedInput.trim()], { type: "text/html" }),
    }),
  ]);
}

export function highlightSubstring(str, substr, color = "#FFCE88") {
  if (!substr) {
    return str;
  }
  const strRegExp = new RegExp(substr, "gi");
  return str?.replace(
    strRegExp,
    `<span style=background-color:${color} !important;>$&</span>`
  );
}

export function mergePromptSearchResponses(
  promptBooks,
  promptsWithPromptBooks
) {
  promptsWithPromptBooks.forEach((promptWithBookInfo) => {
    if (promptWithBookInfo.promptBook) {
      const matchIndex = promptBooks.findIndex(
        (promptBook) => promptBook.id === promptWithBookInfo.promptBookId
      );

      if (matchIndex === -1) {
        const { promptBook, ...prompt } = promptWithBookInfo;
        promptBooks.push({
          promptMatch: true,
          ...promptBook,
          prompts: [prompt],
        });
      } else if (promptBooks[matchIndex].promptMatch) {
        // This book is in the array but not with all of its prompts because it has only matched on
        // prompt criteria and not prompt book criteria.
        let existing = promptBooks[matchIndex];
        delete promptWithBookInfo.promptBook; // don't need
        existing.prompts.push(promptWithBookInfo);
        promptBooks[matchIndex] = existing;
      }
    }
  });

  return promptBooks;
}

export const onEnterPress = (action) => (e) => {
  if (e.keyCode === 13 && e.shiftKey === false) {
    e.preventDefault();
    action();
  }
};

/** Email validation of a string */

const Joi = require("joi");

export function validateEmailString(input) {
  const emailSchema = Joi.string().email({ tlds: { allow: false } });
  if (emailSchema.validate(input).error) {
    return false;
  } else {
    return true;
  }
}

/** Tests if a given value is a valid number. Parsable strings return true. */
export function exists(value) {
  if (isString(value)) {
    value = parseInt(value);
  }
  return !isNil(value) && !isNaN(value);
}

export function isValidAmount(intVal) {
  return !(isNaN(intVal) || intVal < 0);
}

export function arrayContainsArray(subset, superset) {
  return difference(subset, superset).length === 0;
}

/** Adds an ellipsis if text length > maxLength.
 * Should usually just use text-overflow css or line clamp css (if multiline text) instead of this */
export function clipTextWithEllipsis(text, maxLength) {
  if (text.length <= maxLength) {
    return text; // Return the original text if it's shorter than or equal to the maxLength.
  } else {
    return text.slice(0, maxLength - 1) + "…"; // Clip the text and add an ellipsis character (…).
  }
}

/** Get the landing page for a given plan type. */
export function getLandingFromAllowedActions(allowedActions) {
  if (allowedActions) {
    let landingPage = "opportunities"; // For standard basic plan
    if (allowedActions.includes(ALLOWED_ACTIONS.conversations)) {
      landingPage = "chat";
    } else if (allowedActions.includes(ALLOWED_ACTIONS.monitor)) {
      landingPage = "monitor";
    }
    return landingPage;
  }
  return "";
}
