import React from "react";
import {
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";

const useStyles = makeStyles(() => ({
  menuText: {
    transition: "0.2s",
    color: "rgba(0,0,0,0.8)",
    fontSize: "14px",
    width: "90%",
    whiteSpace: "pre-wrap",
    textWrap: "nowrap !important",
  },
  selectedMenuText: {
    transition: "0.2s",
    color: "rgb(25, 118, 210)",
    fontSize: "14px",
    width: "90%",
    whiteSpace: "pre-wrap",
    fontWeight: 500,
    textWrap: "nowrap !important",
  },
  menuImage: {
    height: "16px",
    width: "16px",
  },
  menuImageSelected: {
    height: "16px",
    width: "16px",
    filter:
      "invert(38%) sepia(98%) saturate(744%) hue-rotate(179deg) brightness(85%) contrast(102%)",
  },
  menuIcon: {
    minWidth: "40px",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    "& .MuiListItemSecondaryAction-root": {
      right: "3px",
      top: "15px",
    },
  },
  lockedListItem: {
    opacity: 0.5,
    transition: "0.2s",
    backgroundColor: "#FFFFFF",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflow: "hidden",
  },
  listItem: {
    transition: "0.2s",
    backgroundColor: "#FFFFFF",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflow: "hidden",
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.1)",
      "& $clearButton": {
        visibility: "visible",
      },
    },
    "&:hover": {
      "& $clearButton": {
        visibility: "visible",
      },
    },
  },
  selectedListItem: {
    transition: "0.2s",
    backgroundColor: "rgb(227, 242, 253)",
    height: "48px",
    margin: "4px 0px 4px 0px",
    borderRadius: "12px",
    paddingLeft: 20,
    overflowX: "hidden",
    "&:hover": {
      backgroundColor: "rgb(222, 240, 252)",
    },
    "&:active": {
      backgroundColor: "rgb(215, 234, 247)",
    },
  },
}));

const CONTACT_SALES_STRING =
  "This feature is not available with your subscription. Contact sales@rule5.io to upgrade.";

/** Menu list item for the side navigation on rule5. */
export const MenuListItem = React.memo(function MenuListItem(props) {
  // icons - {custom: custom icon / image / JSX, default: empty / unselected image src, filled: selected image src}
  const { icons, onClickFunction, linkTo, text, locked } = props;

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Tooltip placement="right" title={locked ? CONTACT_SALES_STRING : ""}>
      <ListItem
        button
        disableRipple
        className={
          locked
            ? classes.lockedListItem
            : location.pathname.startsWith(linkTo)
            ? classes.selectedListItem
            : classes.listItem
        }
        onClick={
          locked
            ? null
            : onClickFunction
            ? onClickFunction
            : linkTo
            ? () => {
                history.push(linkTo);
              }
            : null
        }
      >
        <ListItemIcon className={classes.menuIcon}>
          {icons.custom ? (
            icons.custom
          ) : icons.filled && location.pathname.startsWith(linkTo) ? (
            <img
              src={icons.filled}
              alt={text}
              className={classes.menuImageSelected}
            />
          ) : (
            <img src={icons.default} alt={text} className={classes.menuImage} />
          )}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: location.pathname.startsWith(linkTo)
              ? classes.selectedMenuText
              : classes.menuText,
          }}
          primary={text}
        />
        {locked && <LockIcon fontSize="24px" />}
      </ListItem>
    </Tooltip>
  );
});

/** Menu list sub item for the side navigation on rule5. Stripped down version of regular menulistitem which shares the same styles */
export const MenuListSubItem = React.memo(function MenuListSubItem(props) {
  const { onClickFunction, linkTo, text, expanded, collapsedIcon, ...rest } =
    props;

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItem
      button
      disableRipple
      className={
        location.pathname.startsWith(linkTo)
          ? classes.selectedListItem
          : classes.listItem
      }
      style={{ paddingLeft: expanded ? "60px" : "16px" }}
      onClick={
        onClickFunction
          ? onClickFunction
          : linkTo
          ? () => {
              history.push(linkTo);
            }
          : null
      }
      {...rest}
    >
      {expanded ? (
        <ListItemText
          classes={{
            primary: location.pathname.startsWith(linkTo)
              ? classes.selectedMenuText
              : classes.menuText,
          }}
          primary={text}
        />
      ) : (
        collapsedIcon
      )}
    </ListItem>
  );
});

/** Menu list header item for the side navigation on rule5. */
export const MenuListHeading = React.memo(function MenuListHeading(props) {
  // icons - {custom: custom icon / image / JSX, default: empty / unselected image src, filled: selected image src}
  const { icons, text, subItems, locked } = props;

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const subItemUrls = subItems.map((subItem) => {
    return subItem.props?.linkTo;
  });

  const isSelected = subItemUrls.some((url) =>
    location.pathname.startsWith(url)
  );

  return (
    <>
      <Tooltip placement="right" title={locked ? CONTACT_SALES_STRING : ""}>
        <ListItem
          button
          disableRipple
          className={locked ? classes.lockedListItem : classes.listItem}
          // TODO make these have real hrefs instead of div with clickhandler
          onClick={
            locked
              ? null
              : () => {
                  history.push(subItemUrls[0]);
                }
          }
        >
          <ListItemIcon className={classes.menuIcon}>
            {icons.custom ? (
              icons.custom
            ) : icons.filled && isSelected ? (
              <img
                src={icons.filled}
                alt={text}
                className={classes.menuImageSelected}
              />
            ) : (
              <img
                src={icons.default}
                alt={text}
                className={classes.menuImage}
              />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.menuText,
            }}
            primary={text}
          />
          {locked && <LockIcon fontSize="24px" />}
        </ListItem>
      </Tooltip>
      <Collapse in={isSelected} sx={{ overflow: "hidden" }}>
        {subItems}
      </Collapse>
    </>
  );
});
