import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { StyledDataGrid } from "../../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../../properties";
import { useParams } from "react-router-dom";
import AgentResultsToolbar from "./common/AgentResultsToolbar";
import { NoSearchResultsOverlay } from "./common/NoResultsOverlay";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useDialog } from "../../context/DialogContext";
import ViewAgentResult from "../../modal/ViewAgentResult";

export default function AccountAgentResults(props) {
  const { agentRows, setDisplayedAgentIndex, columns } = props;

  const { agentId } = useParams();
  const apiRef = useGridApiRef();
  const defaultScrolled = React.useRef(false);
  const dialog = useDialog();

  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const getRowHeight = React.useCallback(() => "auto", []);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [sortModel, setSortModel] = React.useState([
    { field: "publishedDate", sort: "desc" },
  ]);

  const scrollToResult = React.useCallback(
    (resultId) => {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
      const rowIndex = allRows.findIndex(
        (row) => row.id === parseInt(resultId)
      );
      apiRef.current.scrollToIndexes({ rowIndex });
    },
    [apiRef]
  );

  // Set feedback for a result by index.
  const setFeedbackById = (id, feedbackInfo) => {
    const index = rows.findIndex((result) => result.agentResultId === id);
    const oldRow = rows[index];
    const updatedRows = rows.toSpliced(index, 1, {
      ...oldRow,
      feedbackInfo: feedbackInfo,
    });
    setRows(updatedRows);
  };

  const loadAgentResults = useCallback(
    (searchVal) => {
      setLoading(true);
      const searchParams = new URLSearchParams();
      if (agentId) {
        searchParams.append("agentId", agentId);
      }
      if (searchVal) {
        searchParams.append("searchValue", searchVal);
      }
      axios
        .get(rule5properties.agentResults + "?" + searchParams.toString())
        .then((res) => {
          const params = new URLSearchParams(document.location.search);
          if (res.data && Array.isArray(res.data)) {
            setRows(
              res.data.map((row, index) => {
                return {
                  ...row,
                  expanded:
                    parseInt(params.get("result")) === row.agentResultId
                      ? true
                      : false,
                };
              })
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      if (agentRows && setDisplayedAgentIndex) {
        const foundAgentIndex = agentRows.findIndex(
          (agent) => agent.agentId === parseInt(agentId)
        );
        if (foundAgentIndex >= 0) {
          setDisplayedAgentIndex(foundAgentIndex);
        } else {
          setDisplayedAgentIndex(-1);
          console.log("Failed to load extra agent info.");
        }
        return () => {
          setDisplayedAgentIndex(-1);
        };
      }
    },
    [agentId, setDisplayedAgentIndex, agentRows]
  );

  useEffect(() => {
    loadAgentResults();
  }, [agentId, agentRows, setDisplayedAgentIndex, loadAgentResults]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const defaultResult = params.get("result");
    const sendFeedback = params.get("feedback");
    if (defaultResult && !defaultScrolled.current && rows.length > 0) {
      setTimeout(() => {
        defaultScrolled.current = true;
        dialog.openModal("", ViewAgentResult, {
          sendFeedback,
          selectedId: parseInt(defaultResult),
          ...getCommonResultsModalProps(),
        });
      }, 1500);
    }
  }, [rows, scrollToResult, defaultScrolled.current]);

  // Call backend API when search value is updated with a timeout interval to prevent searching while typing.
  const searchThrottleTimeout = React.useRef();
  useEffect(() => {
    clearTimeout(searchThrottleTimeout.current);
    // Reload if search value has been removed or if more than one char length
    if (searchValue.trim().length !== 1) {
      searchThrottleTimeout.current = setTimeout(() => {
        loadAgentResults(searchValue);
      }, 300);
    }
  }, [searchValue, loadAgentResults]);

  const setSelectedAgentResult = (agentResultId, displayedIndex) => {
    if (Number.isFinite(displayedIndex) && displayedIndex >= 0) {
      apiRef?.current.scrollToIndexes({
        rowIndex: displayedIndex,
        colIndex: 0,
      });
    }
    setRowSelectionModel([agentResultId]);
    dialog.openModal("", ViewAgentResult, {
      selectedId: agentResultId,
      ...getCommonResultsModalProps(),
    });
  };

  const getCommonResultsModalProps = () => {
    return {
      agentResults: apiRef.current.getSortedRows(),
      setSelectedAgentResult: setSelectedAgentResult,
      setFeedbackById: setFeedbackById,
      apiRef: apiRef,
    };
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
      <StyledDataGrid
        getRowClassName={(params) => {
          return params.row.expanded ? "expandedRowClass" : null;
        }}
        apiRef={apiRef}
        disableColumnMenu
        getRowHeight={getRowHeight}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        slots={{
          toolbar: AgentResultsToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoSearchResultsOverlay,
        }}
        slotProps={{
          toolbar: {
            searchValue: searchValue,
            setSearchValue: setSearchValue,
          },
        }}
        onRowClick={(e) => {
          setSelectedAgentResult(e.id);
        }}
        localeText={{ noRowsLabel: "No results found." }}
        loading={loading}
        pageSizeOptions={[10, 25, 50]}
        sortModel={sortModel}
        sortingOrder={["desc", "asc"]} // removing the "null" third option since it sends the sort order to default id-based which is confusing for user
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          // look into the download only including the selected row
        }}
        rowSelectionModel={rowSelectionModel}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.agentResultId}
        sx={{ mx: "30px" }}
      />
    </Box>
  );
}
