import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import {
  Popper,
  Fade,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { throttle, capitalize } from "lodash";
import { useDialog } from "../context/DialogContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  CustomPopper,
  GrayCircularProgress,
  StyledLabel,
  StyledTextfield,
} from "../common/StyledComponents";
import { rule5properties } from "../properties";
import Image from "../common/Image";
import { useDisplayContext } from "../context/DisplayContext";
import { useUser } from "../context/UserContext";
import { PLEASE_UPGRADE_TEXT } from "../common/Utils";
import { useOpportunityCompanies } from "../api/opportunities";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    minHeight: 500,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  autocomplete: {
    paddingTop: 14,
  },
  textfield: {
    marginTop: 25,
    marginBottom: 0,
  },
  option: {
    backgroundColor: "white",
    boxSizing: "border-box",
    height: 60,
    margin: "5px",
    padding: "10px",
    transition: ".2s",
    borderRadius: "5px",
    textTransform: "none",
  },
  optionText: {
    textTransform: "none",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  listbox: {
    backgroundColor: "",
    padding: 0,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "5px 0px 5px 0px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  optionDomain: {
    marginLeft: "20px",
    textAlign: "right",
    width: "100%",
    paddingTop: "3px",
    paddingRight: "20px",
    opacity: 0.7,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  stackFill: {
    flexGrow: 1,
  },
}));

const VALID_EXCHANGES = ["NYSE", "NASDAQ"];

export default function ResearchCompany(props) {
  const { defaultCompany = null } = props;

  const classes = useStyles();
  const history = useHistory();
  const dialog = useDialog();
  const user = useUser();

  const [value, setValue] = React.useState(defaultCompany);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [domain, setDomain] = React.useState("");
  const [functionalAreas, setFunctionalAreas] = React.useState([]);
  const [searching, setSearching] = React.useState(false);
  const [presubmit, setPresubmit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [researchExists, setResearchExists] = React.useState(false);
  const [functionalAreaValue, setFunctionalAreaValue] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [error, setError] = React.useState(null);
  const [consumptionCount, setConsumptionCount] = React.useState(null);
  const [verificationMethod, setVerificationMethod] = React.useState("AI");
  const functionalAreaInput = React.useRef();
  const { refetch } = useOpportunityCompanies();

  // Timeout before executing API call for company search
  const newSearchTimeout = React.useRef();
  // The current company search value
  const newSearchInput = React.useRef("");

  const displayContext = useDisplayContext();

  let noOptionsMessage = "No options found.";

  const beginResearch = (event) => {
    setLoading(true);
    // If this is a new company, create new company.
    let params = {
      ...(value.id > 0 && { companyId: value.id }),
      companyName: value.name,
      domain: domain,
      functionalArea: functionalAreaValue,
      verificationMethod: verificationMethod,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    setTimeout(() => {
      axios.post(rule5properties.skinny, params, options).then((res) => {
        let data = res.data;
        if (res.status === 200) {
          // Update the react-query cache with new response
          refetch();
          let params = {
            operation: "Create",
            module: "Research",
            opportunityId: data.id,
            comment:
              additionalInfo.trim().length > 0 ? additionalInfo : undefined,
            verificationMethod: verificationMethod,
          };
          let options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          };
          axios
            .post(rule5properties.requests, params, options)
            .then((res) => {
              setLoading(false);
              dialog.closeModal();
              if (!defaultCompany) {
                history.push(
                  `/main/opportunities/${data.id}/research?requested=true${
                    displayContext.displayMode === "iframe"
                      ? "&display=iframe"
                      : ""
                  }`
                );
              }
            })
            .catch((error) => {
              console.log("Error occurred while requesting research.");
            });
        } else {
          setLoading(false);
          if (data.code === "INVALID_ARGUMENT") {
            setError(data.message);
          }
        }
      });
    }, 1000);
  };

  const handleCompanyChange = (event, newInputValue) => {
    setSearchValue(newInputValue.replace(/\w+/g, capitalize));
    setFunctionalAreaValue(null);
    if (newInputValue.length > 0) {
      if (value) {
        if (value.name !== newInputValue) {
          setSearchOpen(true);
          setDomain("");
        }
      } else {
        setSearchOpen(true);
        setDomain("");
      }
    } else {
      setSearchOpen(false);
    }
  };

  const handleAreaChange = (event, newInputValue) => {
    setFunctionalAreaValue(newInputValue);
  };

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  // Attempts to load functional area first from Org preferences, then by default list of values if not successful.
  const loadFunctionalAreas = () => {
    axios.get(rule5properties.orgFunctionalAreas).then((res) => {
      if (res.data) {
        setFunctionalAreas(res.data);
      } else {
        axios
          .get(`${rule5properties.values}?name=FunctionalArea`)
          .then((res) => {
            if (res.data.values) {
              setFunctionalAreas(res.data.values);
            } else {
              console.log("Error loading Functional Areas: " + res);
            }
          });
      }
    });
  };

  const loadCompanyList = (resultList) => {
    let newOptions = [];
    if (Array.isArray(resultList)) {
      newOptions = [...newOptions, ...resultList];
    }
    if (newOptions.find((x) => x.name === searchValue) === undefined) {
      newOptions.unshift({
        id: "-1",
        name: searchValue,
        company_info: {},
      });
    }
    setSearching(false);
    setCompanies(newOptions);
  };

  const fetchCompanies = React.useCallback(
    throttle(async (input) => {
      const result = await axios
        .get(`${rule5properties.companies}?name=${input}`)
        .catch((err) => {
          console.log("Error fetching companies: " + err);
        });
      // Only set the company list in state if the searched input matches the current input.
      if (encodeURIComponent(newSearchInput.current) === input) {
        if (result.data) {
          loadCompanyList(result.data);
          // Fill in the domain if it was a defaultCompany which didn't have
          // domain available upstream.
          if (defaultCompany && !searchOpen && !domain) {
            setDomain(
              result.data.find((company) => company.id === defaultCompany.id)
                ?.domain
            );
          }
        }
      }
    }, 200),
    [searchValue]
  );

  React.useEffect(() => {
    axios
      .get(rule5properties.opptyConsumption)
      .then((res) => {
        setConsumptionCount(res.data?.count);
      })
      .catch((error) => {
        console.log("Error occurred while getting consumption count.");
      });
  }, []);

  React.useEffect(() => {
    loadFunctionalAreas();
  }, []);

  React.useEffect(() => {
    let active = true;

    if (searchValue === "") {
      setCompanies(value ? [value] : []);
      return undefined;
    }

    if (searchValue.length > 1) {
      setSearching(true);
      setCompanies([]);

      newSearchInput.current = searchValue;

      clearTimeout(newSearchTimeout.current);
      newSearchTimeout.current = setTimeout(() => {
        fetchCompanies(encodeURIComponent(searchValue));
      }, 300);
    } else {
      setCompanies([]);
      setSearching(true);
    }

    return () => {
      active = false;
    };
  }, [value, searchValue, fetchCompanies]);

  // Domain and functional area have been filled, or functional area has been filled for an existing company.
  useEffect(() => {
    if (
      value === null ||
      value === undefined ||
      functionalAreaValue === null ||
      functionalAreaValue?.trim().length === 0
    ) {
      setPresubmit(false);
    } else if (
      !(
        functionalAreaValue === "" ||
        functionalAreaValue === null ||
        domain === "" ||
        domain === null
      )
    ) {
      if (value.id > 0) {
        // Call API to check if company + functional area research already exists.
        axios
          .get(
            rule5properties.getOpportunityList +
              `?companyId=${encodeURIComponent(
                value.id
              )}&functionalArea=${encodeURIComponent(functionalAreaValue)}`
          )
          .then((res) => {
            if (res.data.length > 0) {
              // Opportunity exists for this company + functional area.
              setResearchExists(true);
              setPresubmit(true);
            } else {
              setResearchExists(false);
              setPresubmit(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setPresubmit(true);
      }
    }
  }, [functionalAreaValue, domain, value]);

  return (
    <div className={classes.container}>
      <Autocomplete
        selectOnFocus
        autoComplete
        autoHighlight
        blurOnSelect
        popupIcon={""}
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        className={classes.autocomplete}
        loading={searching}
        loadingText={
          searchValue.length < 2 ? "Continue typing..." : <SearchLoading />
        }
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        onInputChange={handleCompanyChange}
        onChange={(event, newValue) => {
          setValue(newValue);
          setPresubmit(false);
          if (newValue !== null) {
            setDomain(newValue.domain ? newValue.domain : "");
          } else {
            setDomain("");
          }
        }}
        value={value}
        id="companySearch"
        noOptionsText={noOptionsMessage}
        getOptionLabel={(option) => option.name}
        filterOptions={(x) => x}
        renderOption={(props, option) => {
          return (
            <Box
              {...props}
              sx={{
                border:
                  option.id === "-1" ? "1px dashed rgba(0,0,0,0.2)" : "none",
              }}
            >
              <div
                style={{
                  minWidth: "30px",
                  display: "flex",
                  marginRight: "16px",
                }}
              >
                {option.id === "-1" ? (
                  <AddCircleOutlineIcon
                    sx={{
                      opacity: 0.5,
                      height: "100%",
                      alignItems: "center",
                      ml: "3px",
                    }}
                  />
                ) : option.companyInfo.icon ? (
                  <Image
                    style={{ maxHeight: "30px", maxWidth: "30px" }}
                    src={option.companyInfo.icon}
                  />
                ) : (
                  <img
                    loading="lazy"
                    width="30"
                    src={`https://logo.clearbit.com/${option.domain}?s=128`}
                    alt=""
                  />
                )}
              </div>
              <Typography
                variant="body1"
                className={classes.optionText}
                sx={{ width: "700px", mt: "2px" }}
              >
                {option.id === "-1" ? option.name : option.name}
              </Typography>
              <Typography variant="caption" className={classes.optionDomain}>
                {option.domain}
              </Typography>
            </Box>
          );
        }}
        // PopperComponent={CustomPopper}
        ListboxProps={{ style: { maxHeight: 400 } }}
        onKeyDown={(event) => {
          if (event.key === "Tab") {
            // Prevent's default 'Enter' behavior.
          }
        }}
        options={companies}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <React.Fragment>
              <StyledLabel>Company Name</StyledLabel>
              <StyledTextfield
                autoFocus
                placeholder="Enter a company name..."
                {...params.InputProps}
                {...rest}
              />
            </React.Fragment>
          );
        }}
      />
      {value !== null && value.id < 1 ? (
        <Fade
          in={typeof value != "undefined" && value.id < 1}
          timeout={250}
          onExit={() => {
            setDomain("");
          }}
        >
          <Box sx={{ mt: "14px" }}>
            <StyledLabel>Domain</StyledLabel>
            <StyledTextfield
              fullWidth
              value={domain}
              onChange={handleDomainChange}
            />
          </Box>
        </Fade>
      ) : null}
      <Fade
        in={value !== null && value !== ""}
        timeout={250}
        onExited={() => {
          setFunctionalAreaValue(null);
        }}
      >
        <Autocomplete
          autoComplete
          autoHighlight
          autoSelect
          openOnFocus
          blurOnSelect
          includeInputInList
          disableClearable
          value={functionalAreaValue}
          onChange={handleAreaChange}
          className={classes.autocomplete}
          noOptionsText={noOptionsMessage}
          classes={{
            option: classes.option,
            listbox: classes.listbox,
          }}
          renderOption={(props, option) => {
            return (
              <Box {...props}>
                <Typography variant="body1" className={classes.optionText}>
                  {option}
                </Typography>
              </Box>
            );
          }}
          ListboxProps={{ style: { maxHeight: 350 } }}
          PopperComponent={CustomPopper}
          options={functionalAreas.sort().map((option) => option)}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
              <React.Fragment>
                <StyledLabel>Functional Area</StyledLabel>
                <StyledTextfield
                  {...params.InputProps}
                  {...rest}
                  autoFocus
                  inputRef={functionalAreaInput}
                />
              </React.Fragment>
            );
          }}
        />
      </Fade>
      <Fade in={functionalAreaValue !== null && functionalAreaValue !== ""}>
        <Box sx={{ mt: "14px" }}>
          <StyledLabel>Additional Info</StyledLabel>
          <StyledTextfield
            multiline
            rows={4}
            fullWidth
            value={additionalInfo}
            onChange={(event) => {
              setAdditionalInfo(event.target.value);
            }}
          />
          <Box sx={{ mt: 1, mx: 2 }}>
            <RadioGroup
              row
              value={verificationMethod}
              onChange={(e) => {
                setVerificationMethod(e.target.value);
              }}
            >
              <FormControlLabel
                disabled={user.userSubscription?.planType === "Basic"}
                value={"Human"}
                control={<Radio />}
                label="Human verified"
              />
              <FormControlLabel
                value={"AI"}
                control={<Radio />}
                label="AI verified"
              />
            </RadioGroup>
            <Typography variant="caption" sx={{ mt: 1, opacity: 0.7 }}>
              {verificationMethod === "AI"
                ? `This AI-generated research will be verified entirely by AI for faster turnaround. AI verified research is typically available within 15 minutes.${
                    user.userSubscription?.planType === "Basic"
                      ? "Research for non-US public companies and private companies requires our premium plan. "
                      : ""
                  }`
                : "Our team of experts will review this AI-generated research for accuracy and reliability. However, this thorough review process may slightly extend the turnaround time to guarantee the best results."}
            </Typography>
          </Box>
        </Box>
      </Fade>
      <Stack justifyContent="flex-end" className={classes.stackFill}>
        <Fade in={presubmit} unmountOnExit>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Confirmation
                user={user}
                beginResearch={beginResearch}
                exists={researchExists}
                company={value}
                functionalArea={functionalAreaValue}
                clearCompany={() => {
                  setValue(null);
                }}
                error={error}
                consumptionCount={consumptionCount}
                verificationMethod={verificationMethod}
              />
            )}
          </Box>
        </Fade>
      </Stack>
    </div>
  );
}

function SearchLoading(props) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography>Searching...</Typography>
      <GrayCircularProgress size={16} />
    </Stack>
  );
}

function Confirmation(props) {
  let {
    user,
    company,
    exists,
    beginResearch,
    functionalArea,
    clearCompany,
    error,
    consumptionCount,
    verificationMethod,
  } = props;

  const allowedActions = user.userSubscription?.allowedActions;
  const disallowed =
    !allowedActions || !allowedActions?.includes("Request Research");
  const limitReached =
    user.userSubscription?.opportunitiesLimit === consumptionCount;
  if (!error) {
    if (disallowed) {
      error = PLEASE_UPGRADE_TEXT;
    } else if (
      (company?.legalRegistrationCountry &&
        company?.legalRegistrationCountry !== "US") ||
      company?.ownership !== "Public"
    ) {
      if (user.userSubscription?.planType === "Basic") {
        // Company is not in the US, so basic plan cannot request this research.
        error =
          "This company is not a US public company. The rule5 Basic subscription only allows research requests for US companies. In order to request this research, you must upgrade your subscription.";
      }
    } else if (limitReached) {
      error = `Your subscription has reached its account quota of ${user.userSubscription?.opportunitiesLimit}. To upgrade your subscription, please contact sales@rule5.io.`;
    }
  }

  if (error) {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
        <Typography
          sx={{
            mt: 2,
            mb: 2,
            width: "100%",
          }}
          color="error"
          variant="caption"
        >
          {error}
        </Typography>
        <Button
          disabled={true}
          variant="contained"
          disableElevation
          sx={{ textTransform: "none", width: "240px", justifySelf: "center" }}
        >
          Begin Research
        </Button>
      </div>
    );
  }

  if (company) {
    return (
      <Box sx={{ mt: 5, ml: 1, mr: 1 }}>
        {exists ? (
          <Button
            onClick={beginResearch}
            variant="contained"
            disableElevation
            sx={{ textTransform: "none", width: "240px" }}
          >
            Begin Research
          </Button>
        ) : (
          <Grid container columns={4} spacing={2}>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: "14px", mb: 1, opacity: 0.9 }}>
                This account/category is not in our database yet. Would you like
                to request this research for
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  {company.name} {functionalArea}
                </span>
                ?
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                sx={{ textTransform: "none", width: "100%" }}
                onClick={clearCompany}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={beginResearch}
                variant="contained"
                disableElevation
                sx={{ textTransform: "none", width: "100%" }}
              >
                Request Research
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  } else return null;
}
