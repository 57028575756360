import { Box, LinearProgress } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { StyledDataGrid } from "../../common/StyledComponents";
import axios from "axios";
import { rule5properties } from "../../properties";
import { useParams } from "react-router-dom";
import AgentResultsToolbar from "./common/AgentResultsToolbar";
import { NoSearchResultsOverlay } from "./common/NoResultsOverlay";

export default function PeopleAgentResults(props) {
  const { agentRows, setDisplayedAgentIndex, columns } = props;

  const { agentId } = useParams();

  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const loadJobChangeLeads = useCallback(
    (searchVal) => {
      setLoading(true);
      const searchParams = new URLSearchParams();
      if (agentId) {
        searchParams.append("agentId", agentId);
      }
      if (searchVal) {
        searchParams.append("searchValue", searchVal);
      }
      axios
        .get(rule5properties.jobChangeLeads + "?" + searchParams.toString())
        .then((res) => {
          console.log(res);
          if (res.data && Array.isArray(res.data)) {
            setRows(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      if (agentRows && setDisplayedAgentIndex) {
        const foundAgentIndex = agentRows.findIndex(
          (agent) => agent.agentId === parseInt(agentId)
        );
        console.log(agentRows);
        if (foundAgentIndex >= 0) {
          console.log(foundAgentIndex);
          setDisplayedAgentIndex(foundAgentIndex);
        } else {
          setDisplayedAgentIndex(-1);
          console.log("Failed to load extra agent info.");
        }
        return () => {
          setDisplayedAgentIndex(-1);
        };
      }
    },
    [agentId, setDisplayedAgentIndex, agentRows]
  );

  // Initial load.
  useEffect(() => {
    loadJobChangeLeads();
  }, [agentId, agentRows, setDisplayedAgentIndex, loadJobChangeLeads]);

  // one-field search throttled.
  const searchThrottleTimeout = React.useRef();

  useEffect(() => {
    clearTimeout(searchThrottleTimeout.current);
    if (searchValue.trim().length > 1) {
      searchThrottleTimeout.current = setTimeout(() => {
        loadJobChangeLeads(searchValue);
      }, 300);
    }
  }, [searchValue, loadJobChangeLeads]);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
      <StyledDataGrid
        disableColumnMenu
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        slots={{
          toolbar: AgentResultsToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoSearchResultsOverlay,
        }}
        slotProps={{
          toolbar: {
            searchValue: searchValue,
            setSearchValue: setSearchValue,
          },
        }}
        loading={loading}
        pageSizeOptions={[10, 25, 50]}
        disableRowSelectionOnClick
        rowHeight={80}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.jobChangeLeadId}
        sx={{ mx: "30px" }}
      />
    </Box>
  );
}
