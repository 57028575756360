import React from "react";
import { Box, Stack } from "@mui/material";
import Toolbar from "../../common/Toolbar";
import AccountAgentResults from "./AccountAgentResults";
import Workspace from "../Workspace";
import { ACCOUNT_EVENTS_COLUMNS } from "./common/monitorColumns";

export default function AccountEvents(props) {
  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        <Stack direction="row" alignItems="center">
          Company signals
        </Stack>
      </Toolbar>
      <Workspace>
        <AccountAgentResults columns={ACCOUNT_EVENTS_COLUMNS} />
      </Workspace>
    </Box>
  );
}
