import { Button, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function AgentResultButton(props) {
  const { resultCount, agentId, type } = props;
  const history = useHistory();
  const [showTooltip, setShowTooltip] = React.useState(false);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }
  }, [showTooltip]);

  return (
    <Tooltip open={showTooltip} title="Still looking for results...">
      <Button
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          if (parseInt(resultCount) !== 0) {
            history.push(`/main/monitor/${type}/results/${agentId}`);
          } else {
            setShowTooltip(true);
          }
        }}
        sx={{
          ml: -1,
          textTransform: "none",
          fontWeight: 400,
          textDecoration: resultCount > 0 && "underline",
          color: resultCount <= 0 ? "rgba(0,0,0,.5)" : undefined,
        }}
      >
        {parseInt(resultCount) > 0 ? resultCount : "No"} result
        {parseInt(resultCount) !== 1 ? "s" : ""}
      </Button>
    </Tooltip>
  );
}
