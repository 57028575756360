import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import EditAgent from "../../../modal/EditAgent";
import React from "react";
import _ from "lodash";
import { AgentName } from "./monitorColumns";

const VALID_MODAL = ["default", "signals", "actions"];

export default function BadgedIconButton(props) {
  const {
    type,
    cellValues,
    dialog,
    icon,
    loadAgentRows,
    onClick = (e) => {
      e.stopPropagation();
      if (VALID_MODAL.includes(type)) {
        dialog.openModal(
          <AgentName
            syncResearchAccounts={cellValues.row.syncResearchAccounts}
            name={cellValues.row.name}
            prependedText={"Configure Agent - "}
          />,
          EditAgent,
          { defaultPage: type, modAgent: cellValues.row, loadAgentRows },
          "md"
        );
      }
    },
  } = props;

  function zeroText(val) {
    if (val === 0) {
      return "No";
    } else return val;
  }

  let errorCount =
    type === "accounts" || type === "people"
      ? parseInt(cellValues.row.accountsErrorCount) ||
        parseInt(cellValues.row.peopleErrorCount)
      : 0;
  let count = 0;
  let displayText = `${type.substring(0, type.length - 1)}${
    count === 1 ? "" : "s"
  }`;

  if (type === "accounts") {
    count = +cellValues.row.accountsCount;
    let accountsActive = parseInt(cellValues.row.accountsActiveCount);
    let accountsError = parseInt(cellValues.row.accountsErrorCount);
    const activeText = `${zeroText(accountsActive)} active ${
      accountsActive === 1 ? "company" : "companies"
    }`;
    const errorText = `${zeroText(accountsError)} invalid ${
      accountsError === 1 ? "company" : "companies"
    }`;
    displayText =
      count > 0 ? (
        <div>
          {accountsActive > 0 && (
            <>
              {activeText}
              <br />
            </>
          )}
          {accountsError > 0 && errorText}
        </div>
      ) : (
        "Companies"
      );
  }
  if (type === "people") {
    count = +cellValues.row.peopleCount;
    displayText = count === 1 ? "person" : "people";
    let peopleActive = parseInt(cellValues.row.peopleActiveCount);
    let peopleError = parseInt(cellValues.row.peopleErrorCount);
    const activeText = `${zeroText(peopleActive)} active ${
      peopleActive === 1 ? "person" : "people"
    }`;
    const errorText = `${zeroText(peopleError)} invalid ${
      peopleError === 1 ? "person" : "people"
    }`;
    displayText =
      count > 0 ? (
        <div>
          {peopleActive > 0 && (
            <>
              {activeText}
              <br />
            </>
          )}
          {peopleError > 0 && errorText}
        </div>
      ) : (
        "People"
      );
  }

  if (type === "signals") {
    count = +cellValues.row.signals?.length || 0;
    displayText = _.capitalize(displayText);
  }
  if (type === "actions") {
    count = 0;
    displayText = _.capitalize(displayText);
  }
  return (
    <Tooltip title={displayText} arrow placement="right">
      <Button
        disableRipple
        onClick={onClick}
        color="inherit"
        sx={{ color: "rgba(0,0,0,.4)", borderRadius: "8px" }}
      >
        <Badge
          onClick={onClick}
          badgeContent={count > 0 ? count : null}
          color={errorCount > 0 ? "error" : "primary"}
          overlap="rectangular"
          size="small"
          sx={{ mt: 0.5, mr: count > 0 ? 0.5 : 0 }}
        >
          {icon}
        </Badge>
      </Button>
    </Tooltip>
  );
}
