import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { rule5AppUrl, rule5properties } from "../properties";
import { getColorFromString } from "../common/Utils";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { StyledTextfield } from "../common/StyledComponents";

/** Modal to display/manage the opportunities that a user has access to. */
export default function UserOpportunities(props) {
  const { userEmail } = props;

  const loadedOpportunitiesRef = React.useRef([]);

  const [opportunities, setOpportunities] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  useEffect(() => {
    axios
      .get(
        rule5properties.getUserOpportunities +
          "/" +
          encodeURIComponent(userEmail)
      )
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          loadedOpportunitiesRef.current = res.data;
          setOpportunities(res.data);
        } else {
          console.log(
            "[rule5] Unable to retrieve accounts/opportunities for this user."
          );
        }
      });
  }, [userEmail]);

  useEffect(() => {
    filterOpportunities(searchValue);
  }, [searchValue]);

  function filterOpportunities(searchValue) {
    const lowercaseSv = searchValue.toLowerCase().trim();
    const filteredOpportunities = loadedOpportunitiesRef.current.filter(
      (opp) =>
        opp.Account.toLowerCase().includes(lowercaseSv) ||
        opp.functionalArea.toLowerCase().includes(lowercaseSv)
    );
    setOpportunities(filteredOpportunities);
  }

  return (
    <Stack sx={{ width: "100%", height: "80vh" }}>
      <StyledTextfield
        placeholder="Search…"
        sx={{ mb: 1 }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <InfiniteScrollList
        items={opportunities}
        pageSize={20}
        ItemComponent={OpportunityRowItem}
        itemPropName="opportunity"
      />
    </Stack>
  );
}

function OpportunityRowItem(props) {
  // console.log("opp");
  const { opportunity } = props;
  // console.log(opportunity);
  return (
    <Stack sx={{ p: 1.5 }} direction="row" alignItems="center">
      <Avatar
        sx={{
          width: "40px",
          height: "40px",
          bgcolor: getColorFromString(opportunity.Account),
        }}
      >
        {opportunity.Account.toUpperCase().charAt(0)}
      </Avatar>
      <Box sx={{ ml: 2, flexGrow: 1 }}>
        <Typography>{opportunity.Account}</Typography>
        <Typography>{opportunity.functionalArea}</Typography>
      </Box>
      {/* <Stack direction="row" alignItems="center">
        <Tooltip title="Open account">
          <IconButton
            size="small"
            onClick={() => {
              console.log(opportunity);
              window.open(
                `${rule5AppUrl}/main/opportunities/${opportunity.id}/research`,
                "_blank"
              );
            }}
          >
            <OpenInNewIcon sx={{ opacity: 0.6 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove access">
          <IconButton size="small">
            <CloseIcon sx={{ opacity: 0.6 }} />
          </IconButton>
        </Tooltip>
      </Stack> */}
    </Stack>
  );
}

function InfiniteScrollList(props) {
  const { items, pageSize, ItemComponent, itemPropName } = props;

  const [loadedItems, setLoadedItems] = React.useState([]);
  const [loadedPages, setLoadedPages] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  let holdScrollLoad = false;

  useEffect(() => {
    setLoadedItems(items.slice(0, pageSize));
  }, [items, pageSize]);

  function loadNextPage() {
    if (!holdScrollLoad) {
      holdScrollLoad = true;
      console.log("Loading more.");
      setLoading(true);
      setTimeout(() => {
        setLoadedItems(items.slice(0, (loadedPages + 1) * pageSize));
        setLoadedPages(loadedPages + 1);
        setLoading(false);
        holdScrollLoad = false;
      }, 300);
    }
  }

  function handleScroll(e) {
    // Load 20 more connections into the list when the user scrolls to the bottom
    if (loadedItems.length < items.length) {
      let element = e.target;
      if (element.clientHeight + element.scrollTop > element.scrollHeight - 1) {
        loadNextPage();
      }
    }
  }

  return (
    <>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <Box
        sx={{
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            margin: "5px 0px 5px 0px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0,0,0,.15)",
          },
        }}
        // onScroll={handleScroll}
      >
        {loadedItems.map((item) => {
          const dynamicProps = {};
          dynamicProps[itemPropName] = item;
          return <ItemComponent {...dynamicProps} />;
        })}
        {loadedItems.length < items.length && (
          <Button
            sx={{ width: "100%", height: "40px", textTransform: "none" }}
            onClick={loadNextPage}
          >
            Load more
          </Button>
        )}
      </Box>
    </>
  );
}
