import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import parse from "html-react-parser";
import { copyMixedHtml, formatDate } from "../common/Utils";
import PreviousNextPicker from "../common/PreviousNextPicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  CancelConfirmButtons,
  StyledTextfield,
  scrollbarStyleThin,
} from "../common/StyledComponents";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useForm } from "react-hook-form";
import axios from "axios";
import { rule5properties } from "../properties";
import OpenResearchButton from "../common/OpenResearchButton";
import { useOpportunityCompanies } from "../api/opportunities";

export default function ViewAgentResult(props) {
  const {
    agentResults,
    sendFeedback,
    selectedId,
    setSelectedAgentResult,
    setFeedbackById,
  } = props;
  // Find the index of the selected result.
  const displayedIndex = agentResults.findIndex(
    (result) => result.agentResultId === selectedId
  );
  const currentResult = agentResults[displayedIndex];
  const [localFeedbackInfo, setLocalFeedbackInfo] = React.useState(
    agentResults[displayedIndex].feedbackInfo
  );
  const [copyStatus, setCopyStatus] = React.useState("Copy summary");
  const [feedbackMode, setFeedbackMode] = React.useState(sendFeedback);

  const { data: opportunityCompanies, isFetching } = useOpportunityCompanies();

  function copySummary() {
    copyMixedHtml(currentResult.summary);
    setCopyStatus("Copied!");
    setTimeout(() => {
      setCopyStatus("Copy summary");
    }, 2000);
  }

  function toggleFeedbackMode() {
    setFeedbackMode(!feedbackMode);
  }

  const companyResearch = opportunityCompanies?.find(
    (company) => company.companyId === currentResult.companyId
  );

  return (
    <Stack
      sx={{ height: "80vh", overflow: "hidden" }}
      flexDirection="column"
      alignItems="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="modalHeader1">{currentResult.title}</Typography>
        <PreviousNextPicker
          mini
          previousTooltip="Previous"
          nextTooltip="Next"
          previousDisabled={displayedIndex === 0}
          nextDisabled={displayedIndex === agentResults.length - 1}
          previousClick={() => {
            const newIndex = displayedIndex - 1;
            setSelectedAgentResult(
              agentResults[newIndex].agentResultId,
              newIndex
            );
          }}
          nextClick={() => {
            const newIndex = displayedIndex + 1;
            setSelectedAgentResult(
              agentResults[newIndex].agentResultId,
              newIndex
            );
          }}
          sx={{ mb: 1, ml: 1 }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: 0.5, width: "100%" }}
      >
        <Typography variant="modalHeader2">
          {currentResult.companyName} - {currentResult.signal}
        </Typography>
        <Typography variant="modalHeader2">
          {formatDate(new Date(currentResult.publishedDate))}
        </Typography>
      </Stack>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.03)",
          margin: "16px 0px 8px 0px",
          padding: "16px 24px",
          borderRadius: "8px",
          overflow: "auto",
        }}
        sx={scrollbarStyleThin}
      >
        {currentResult.summary && parse(currentResult.summary)}
      </Box>
      {!feedbackMode && (
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ width: "100%", height: "40px" }}
        >
          <Box>
            <Button
              endIcon={<ContentCopyIcon />}
              onClick={copySummary}
              sx={{ width: "140px", mr: 1 }}
            >
              {copyStatus}
            </Button>
            <Button
              endIcon={<RateReviewOutlinedIcon />}
              onClick={toggleFeedbackMode}
            >
              Feedback
            </Button>
          </Box>
          <Stack direction="row" spacing={1}>
            <OpenResearchButton
              agentResult={currentResult}
              userOpportunities={companyResearch?.oppties}
              isFetching={isFetching}
            />
            <Button
              disableElevation
              sx={{ width: "fit-content", borderRadius: "8px" }}
              variant="contained"
              endIcon={<OpenInNewIcon />}
              onClick={() => {
                window.open(currentResult.link, "_blank");
              }}
            >
              View full article
            </Button>
          </Stack>
        </Stack>
      )}
      {feedbackMode && (
        <FeedbackForm
          defaultFeedbackInfo={localFeedbackInfo}
          setLocalFeedbackInfo={setLocalFeedbackInfo}
          toggleFeedbackMode={toggleFeedbackMode}
          selectedId={selectedId}
          setFeedbackById={setFeedbackById}
        />
      )}
    </Stack>
  );
}

function FeedbackForm(props) {
  const {
    defaultFeedbackInfo,
    selectedId,
    toggleFeedbackMode,
    setFeedbackById,
    setLocalFeedbackInfo,
  } = props;

  const defaults = {
    rating: defaultFeedbackInfo?.rating || "",
    comment: defaultFeedbackInfo?.comment || "",
    tags: defaultFeedbackInfo?.tags || [],
    notTrue: defaultFeedbackInfo?.tags?.includes("notTrue") || false,
    notHelpful: defaultFeedbackInfo?.tags?.includes("notHelpful") || false,
  };
  const methods = useForm({ defaultValues: defaults });

  // 0 not selected, 1 thumbs up, 2 thumbs down
  const [thumbSelection, setThumbSelection] = React.useState(
    defaultFeedbackInfo
      ? defaultFeedbackInfo.rating === "thumbsUp"
        ? 1
        : 2
      : 0
  );
  const [feedbackStatus, setFeedbackStatus] = React.useState("");

  function sendFeedbackFunction(value) {
    const tags = [];
    if (value.notTrue) {
      tags.push("notTrue");
    }
    if (value.notHelpful) {
      tags.push("notHelpful");
    }
    const params = {
      rating: value.rating,
    };
    if (value.rating === "thumbsDown") {
      params.comment = value.comment;
      params.tags = tags;
    }
    axios
      .post(`${rule5properties.agentResults}/${selectedId}/feedback`, params)
      .then((res) => {
        if (res.status === 200) {
          setFeedbackStatus("");
          setLocalFeedbackInfo(params);
          setFeedbackById(selectedId, params);
        } else if (res.data?.message) {
          console.log("[rule5] Failed to submit feedback.");
          setFeedbackStatus(res.data.message);
        }
      });
  }

  useEffect(() => {
    if (thumbSelection === 1) {
      methods.setValue("rating", "thumbsUp");
    } else if (thumbSelection === 2) {
      methods.setValue("rating", "thumbsDown");
    }
  }, [thumbSelection, methods]);

  return (
    <Box sx={{ width: "100%" }}>
      <form
        style={{ width: "100%" }}
        onSubmit={methods.handleSubmit(sendFeedbackFunction)}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <IconButton
              disabled={defaultFeedbackInfo}
              onClick={() => {
                setThumbSelection(1);
              }}
            >
              {thumbSelection === 1 ? (
                <ThumbUpAltIcon />
              ) : (
                <ThumbUpAltOutlinedIcon />
              )}
            </IconButton>
            <IconButton
              disabled={defaultFeedbackInfo}
              onClick={() => {
                setThumbSelection(2);
              }}
            >
              {thumbSelection === 2 ? (
                <ThumbDownAltIcon />
              ) : (
                <ThumbDownAltOutlinedIcon />
              )}
            </IconButton>
            {thumbSelection === 0 && (
              <Typography variant="caption" sx={{ ml: 1 }}>
                Select thumbs up or thumbs down.
              </Typography>
            )}
          </Stack>
          <Collapse
            in={thumbSelection === 2}
            orientation="horizontal"
            unmountOnExit
          >
            <Box sx={{ ml: 2, textWrap: "nowrap" }}>
              <FormControlLabel
                disabled={defaultFeedbackInfo}
                sx={{ mr: 2 }}
                control={
                  <Checkbox
                    {...methods.register("notTrue")}
                    checked={methods.watch("notTrue")}
                    size="small"
                    style={{ padding: "6px" }}
                  />
                }
                label="Not true"
              />
              <FormControlLabel
                disabled={defaultFeedbackInfo}
                control={
                  <Checkbox
                    {...methods.register("notHelpful")}
                    checked={methods.watch("notHelpful")}
                    size="small"
                    style={{ padding: "6px" }}
                  />
                }
                label="Not helpful / relevant"
              />
            </Box>
          </Collapse>
        </Stack>
        <StyledTextfield
          {...methods.register("comment", {
            minLength: 1,
          })}
          disabled={defaultFeedbackInfo}
          autoFocus
          placeholder="Share feedback on this signal..."
          sx={{
            display: "block",
            fontSize: "16px",
            minHeight: "100px",
            overflowY: "auto",
            flexGrow: 1,
          }}
          multiline
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Collapse in={feedbackStatus.trim().length > 0}>
            <Typography sx={{ ml: 1, mt: -0.5 }} color="error">
              {feedbackStatus}
            </Typography>
          </Collapse>
          <CancelConfirmButtons
            sx={{ width: "360px" }}
            cancelText={defaultFeedbackInfo ? "Back" : "Cancel"}
            cancelFunction={toggleFeedbackMode}
            confirmText={
              defaultFeedbackInfo ? "Feedback received" : "Send feedback"
            }
            confirmDisabled={thumbSelection === 0 || defaultFeedbackInfo}
          />
        </Stack>
      </form>
    </Box>
  );
}
